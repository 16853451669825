import {
  BackendServicesErrorCode,
  CwAppErrorCode,
  CwServerErrorCode,
} from '@/models/enums';

interface ErrorEntry {
  translationKey: `error__${string}`;
  code: CwErrorCode;
  context?: ErrorContext;
}

// When editing this file, please make sure to keep the Notion documentation synced:
// https://www.notion.so/SWS-Errors-e35c206e6b2841ef95516c9e0769c475?pvs=4
export const errorRegistry: ErrorEntry[] = [
  {
    code: CwServerErrorCode.GooglePlacesPlaceDetailsIssue,
    translationKey: 'error__google_places_place_details_issue',
  },
  {
    code: CwAppErrorCode.StripeCardPaymentConfirmationFailed,
    translationKey: 'error__stripe_card_payment_confirmation_failed',
  },
  {
    code: CwAppErrorCode.UserGeolocationFailed,
    translationKey: 'error__user_geolocation_failed',
  },
  {
    code: BackendServicesErrorCode.AuthInvalidCode,
    translationKey: 'error__auth_invalid_code',
  },
  {
    code: BackendServicesErrorCode.UserRfidTagAlreadyExists,
    translationKey: 'error__user_rfid_tag_already_exists',
  },
  {
    code: BackendServicesErrorCode.ContextDeadlineExceeded,
    context: 'add-rfid-tag',
    translationKey: 'error__add_rfid_tag__unknown',
  },
  {
    code: CwAppErrorCode.StripeAddPaymentCardFailed,
    translationKey: 'error__stripe_add_payment_card_failed',
  },
  {
    code: CwAppErrorCode.CaptchaNotVerified,
    translationKey: 'error__captcha_not_verified',
  },
  {
    code: CwAppErrorCode.CaptchaSomethingWentWrong,
    translationKey: 'error__captcha_something_went_wrong',
  },
  {
    code: BackendServicesErrorCode.UserTooYoung,
    translationKey: 'error__user_too_young',
  },
  {
    code: BackendServicesErrorCode.UserHasBillableRentals,
    context: 'delete-user',
    translationKey: 'error__delete_user__user_active_trip',
  },
  {
    code: BackendServicesErrorCode.UserIsSuspended,
    context: 'delete-user',
    translationKey: 'error__delete_user__user_is_suspended',
  },
  {
    code: BackendServicesErrorCode.UserHasContract,
    context: 'delete-user',
    translationKey: 'error__delete_user__user_active_leasing_contract',
  },
  {
    code: BackendServicesErrorCode.UserBalanceNotNull,
    context: 'delete-user',
    translationKey: 'error__delete_user__user_credit_wallet',
  },
  {
    code: BackendServicesErrorCode.UserHasBillableBenefits,
    context: 'delete-user',
    translationKey: 'error__delete_user__user_active_subscription',
  },
  {
    code: BackendServicesErrorCode.RentalTooMuchVehiclesRented,
    translationKey: 'error__rental_too_much_vehicules_rented',
  },
  {
    code: BackendServicesErrorCode.PaymentOfferNotAvailable,
    translationKey: 'error__payment_offer_not_available',
  },
  {
    code: BackendServicesErrorCode.PaymentWrongBenefitStatus,
    translationKey: 'error__payment_wrong_benefit_status',
  },
  {
    code: BackendServicesErrorCode.AuthUserNotFound,
    context: 'account-settings',
    translationKey: 'error__account_settings__auth_user_not_found',
  },
  {
    // No passcode sent (user is most likely a bot)
    code: BackendServicesErrorCode.NotifierIsSpam,
    translationKey: 'error__captcha_something_went_wrong',
  },
  {
    // Passcode is incorrect (user is most likely a bot)
    code: BackendServicesErrorCode.NotifierCaptchaFailed,
    translationKey: 'error__captcha_something_went_wrong',
  },
];
